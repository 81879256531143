// 虚拟号
import { Request } from '@/http/request'
// 虚拟号列表查询
export function virtualNumberList(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/list',parameter)
}
// 虚拟号解绑
export function relationshipUnbinding(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/auth-un-bind',parameter)
}
// 虚拟号批量解绑
export function batchaAuthUnBind(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/batch-auth-un-bind',parameter)
}
//虚拟号绑定
export function virtualBinding(parameter: any) {
    return Request.axiosInstance.post('/open/number-activation/cmcc-net-axb-bind',parameter)
}
//虚拟号批量绑定
export function batchAuthBind(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/batch-auth-bind',parameter)
}
//可用数量查询
export function numberCount(parameter: any) {
  return Request.axiosInstance.post('/open/cmcc-auth/area-number-count',parameter)
}
//查询工作号配置
export function authConfigQuery(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/authConfigQuery',parameter)
}
//更新工作号配置
export function updateAuthConfig(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/updateAuthConfig',parameter)
}

//虚拟号导出
export function cmccAuthExport(parameter: any) {
    return Request.axiosInstance.post('/open/cmcc-auth/cmcc-auth-export',parameter)
}
export default {numberCount, virtualNumberList,relationshipUnbinding,virtualBinding,batchaAuthUnBind,batchAuthBind, authConfigQuery, updateAuthConfig,cmccAuthExport}